import { Breadcrumb, Button, Card, Form, Input, message } from "antd";
import Layout from "../../../../components/Layout";
import PageHeader from "../../../../components/PageHeader";
import PageContent from "../../../../components/PageContent";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    getAuthInfo,
    setUser,
    updateUserInfo,
} from "../../../../store/slices/auth";

export default function UserSettings() {
    const { user } = useSelector((state: GlobalState) => state.auth);
    const dispatch = useDispatch();

    const saveInfo = async (values: {
        name: string;
        first_name: string;
        last_name: string;
        email: string;
        id: number;
    }) => {
        const { success } = await updateUserInfo({ ...user, ...values });
        console.log("YENI VERILER : ", { ...user, ...values });
        if (success) {
            const { user: authUser } = await getAuthInfo();
            dispatch(setUser(authUser));
            message.success("User info updated successfully");
        }
    };
    return (
        <Layout>
            <PageHeader>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/games/manage-games">Home</Link>,
                        },
                        { title: "User settings" },
                    ]}
                />
            </PageHeader>
            <PageContent>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 100,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Card style={{ width: "400px" }}>
                            <Form
                                layout="vertical"
                                onFinish={saveInfo}
                                initialValues={{
                                    email: user?.email,
                                    username: user?.username,
                                    first_name: user?.first_name,
                                    last_name: user?.last_name,
                                    gsm: user?.gsm,
                                }}
                            >
                                <Form.Item name="first_name" label="First Name">
                                    <Input placeholder="First Name" />
                                </Form.Item>
                                <Form.Item name="last_name" label="Last Name">
                                    <Input placeholder="Last Name" />
                                </Form.Item>
                                <Form.Item name="email" label="Email">
                                    <Input placeholder="Email" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        block
                                        htmlType="submit"
                                    >
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </div>
                </div>
            </PageContent>
        </Layout>
    );
}
