import Sidebar from "../Menu";
import "./style.css";
import { useSelector } from "react-redux";

export default function Layout({ children }: { children: any }) {
    const { theme } = useSelector((state: GlobalState) => state.ui);
    return (
        <div className="Layout" data-color-theme={theme}>
            <div className="layout__col__menu">
                <Sidebar />
            </div>
            <div className="layout__col__content">{children}</div>
        </div>
    );
}
