import { Avatar } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function SelectedGame() {
    const { selectedGame } = useSelector((state: GlobalState) => state.ui);
    const [sg, setSg] = useState<Game | null>();

    useEffect(() => {
        setSg(selectedGame);
    }, [selectedGame]);

    if (!sg) return null;
    return (
        <div
            style={{
                background: "white",
                marginLeft: 20,
                border: "1px solid #d9d9d9",
                height: 40,
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                borderRadius: 6,
            }}
        >
            <span style={{ marginRight: 10, color: "#919297" }}>{sg.name}</span>
            <Avatar src={sg.logo} size={"small"} />
        </div>
    );
}
