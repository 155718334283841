import {
    Button,
    Card,
    Col,
    Input,
    Popconfirm,
    Row,
    Space,
    Tooltip,
    message,
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
    DeleteOutlined,
    EditOutlined,
    SaveOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { deleteApiKey, editApiKey } from "../../../../../store/slices/studio";

export default function KeyBox({
    keyInfo,
    refreshList,
}: {
    keyInfo: ApiKey;
    refreshList: () => void;
}) {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [editName, setEditName] = useState<string>("");

    const saveEdit = async () => {
        const resp = await editApiKey({ name: editName, id: keyInfo.id });

        if (resp.success) {
            message.success("Nickname edited successfully");
            setEditMode(false);
            refreshList();
        }
    };

    const deleteKey = async () => {
        const resp = await deleteApiKey({ id: keyInfo.id });

        if (resp.success) {
            message.success("Key deleted successfully");
            refreshList();
        }
    };

    return (
        <Card
            title={
                editMode ? (
                    <>
                        <Space>
                            <Input
                                defaultValue={keyInfo.name}
                                onChange={(e) => {
                                    setEditName(e.target.value);
                                }}
                            />
                            <Button
                                onClick={() => {
                                    saveEdit();
                                }}
                                size="small"
                                type="primary"
                                icon={<SaveOutlined />}
                            ></Button>
                            <Button
                                onClick={() => {
                                    setEditMode(false);
                                }}
                                size="small"
                                type="default"
                                icon={<CloseOutlined />}
                            ></Button>
                        </Space>
                    </>
                ) : (
                    keyInfo.name
                )
            }
            actions={[
                <EditOutlined
                    onClick={() => {
                        setEditMode(true);
                    }}
                />,
                <Popconfirm
                    title="Delete the key"
                    description="Are you sure to delete this key?"
                    onConfirm={() => {
                        deleteKey();
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined />
                </Popconfirm>,
            ]}
        >
            <Row>
                <Col span={12}>Key</Col>
                <Col
                    span={12}
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <CopyToClipboard
                        text={keyInfo.api_key}
                        onCopy={() => {
                            message.success("Copied to clipboard");
                        }}
                    >
                        <Button type="primary">Copy</Button>
                    </CopyToClipboard>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={12}>Secret</Col>
                <Col
                    span={12}
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    {!keyInfo.api_secret ? (
                        <Tooltip title="You are only allowed to copy the secret key, on new key creation.">
                            <Button type="primary" disabled>
                                Copy
                            </Button>
                        </Tooltip>
                    ) : (
                        <CopyToClipboard
                            text={keyInfo.api_secret}
                            onCopy={() => {
                                message.success("Copied to clipboard");
                            }}
                        >
                            <Button type="primary">Copy</Button>
                        </CopyToClipboard>
                    )}
                </Col>
            </Row>
        </Card>
    );
}
