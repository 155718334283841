import { createSlice } from "@reduxjs/toolkit";

const initialState: UIReducerInitialState = {
    theme: "light",
    menuOpen: false,
    selectedGame: null,
    activePage: "manage-games",
};
const uiReducer = createSlice({
    name: "ui",
    initialState,
    reducers: {
        toggleMenu: (state, action) => {
            state.menuOpen = action.payload;
        },
        toggleTheme: (state, action) => {
            state.theme = action.payload;
        },
        selectGame: (state, action) => {
            state.selectedGame = action.payload;
        },
        setActivePage: (state, action) => {
            state.activePage = action.payload;
        },
    },
});

export const { toggleMenu, toggleTheme, selectGame, setActivePage } =
    uiReducer.actions;
export default uiReducer.reducer;
