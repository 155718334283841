import dayjs from "dayjs";

export function generateSlug(input: string): string {
    const normalized = input.replace(/[^\w\s]/g, "").toLowerCase();
    return normalized.replace(/\s+/g, "_");
}

export function removeEmptyStrings(array: any[]) {
    return array.filter((item) => item !== "" && item !== "");
}

export function addDaysToTimestamp(timestamp: number, days: number) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const newTimestamp =
        new Date(timestamp).getTime() + days * millisecondsPerDay;
    return newTimestamp;
}

export function convertUtcToLocal(utcDateString: string) {
  // Parse the UTC date string into a Date object
  const utcDate = new Date(utcDateString);

  // Get the time zone offset in minutes between UTC and local time
  const timeZoneOffsetMinutes = utcDate.getTimezoneOffset();

  // Apply the offset to get the local time in milliseconds
  const localTimeMilliseconds =
    utcDate.getTime() - timeZoneOffsetMinutes * 60 * 1000;

  // Create a new Date object with the local time
  const localDate = new Date(localTimeMilliseconds);

  return localDate;
}

export const convertLocalToUTC = (t: any) =>
  new Date(t).toLocaleString("en-US", {
    timeZone: "UTC",
  });

export const fixStatus = (raw_start_time: string, raw_end_time: string) => {
    const date1 = dayjs(raw_start_time);
    const date2 = dayjs(raw_end_time);

    if (
        date2.isBefore(
            new Date().toLocaleString("en-US", {
                timeZone: "UTC",
            })
        )
    ) {
        return "finished";
    } else if (
        date1.isAfter(
            new Date().toLocaleString("en-US", {
                timeZone: "UTC",
            })
        )
    ) {
        return "upcoming";
    } else {
        return "ongoing";
    }
};

export const tagColor = (status: string): "magenta" | "orange" | "green" => {
    switch (status) {
        case "ongoing":
            return "green";

        case "finished":
            return "magenta";

        case "upcoming":
            return "orange";

        default:
            return "green";
    }
};

export const shortNumber = (number: number) => {
    if (number < 100000) return parseFloat("" + number).toFixed(2);
    if (Math.abs(number) >= 1000000000) {
        return (number / 1000000000).toFixed(2) + "B";
    } else if (Math.abs(number) >= 1000000) {
        return (number / 1000000).toFixed(2) + "M";
    } else if (Math.abs(number) >= 1000) {
        return (number / 1000).toFixed(2) + "K";
    }

    return number.toString();
};
