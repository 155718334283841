import Login from "../pages/Login";
import Games from "../pages/Games";
import Analytics from "../pages/Analytics";
import Rewards from "../pages/Rewards";
import Arena from "../pages/Arena";
import NewGame from "../pages/Games/subPages/Add";
import EditGame from "../pages/Games/subPages/Edit";
import ManageRewardPool from "../pages/Rewards/subPages/ManageRewardPool";
import SetRegionFactors from "../pages/Rewards/subPages/RegionFactors";
import SetLimits from "../pages/Rewards/subPages/SetLimits";
import GameDetails from "../pages/Game";
import APIKeys from "../pages/Games/subPages/APIKeys";
import { Result } from "antd";
import SetAllocations from "../pages/CashRewards/subPages/SetAllocations";
import Settings from "../pages/Game/subPages/Rewards/Settings";
import StudioSettings from "../pages/Profile/Settings";
import Tournaments from "../pages/Game/subPages/Tournaments";
import NewTournament from "../pages/Game/subPages/Tournaments/subPages/Add";
import TopUpBalance from "../pages/CashRewards/subPages/TopUpBalance";
import LevelRewards from "../pages/Rewards/subPages/LevelRewards";
import DailyLimit from "../pages/Rewards/subPages/DailyLimit";
import RegionFactors from "../pages/Rewards/subPages/RegionFactors";
import UserSettings from "../pages/Profile/User/Settings";
import EditTournament from "../pages/Game/subPages/Tournaments/subPages/Edit";
import CashTournaments from "../pages/Game/subPages/CashTournaments";
import CashTournamentPayment from "../pages/Game/subPages/CashTournaments/subPages/CashPay";
import NewCashTournament from "../pages/Game/subPages/CashTournaments/subPages/Add";
import EditCashTournament from "../pages/Game/subPages/CashTournaments/subPages/Edit";
import Customization from "../pages/Game/subPages/Customization";


export const ROUTES = [
    {
        path: "/",
        element: <Games />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/games/manage-games",
        element: <Games />,
    },
    {
        path: "/games/add",
        element: <NewGame />,
    },
    {
        path: "/games/api-keys",
        element: <APIKeys />,
    },
    {
        path: "/game/edit/:gameId",
        element: <EditGame />,
    },
    {
        path: "/game/dashboard",
        element: <GameDetails />,
    },
    {
        path: "/rewards/region-factors",
        element: <RegionFactors />,
    },
    {
        path: "/rewards/level-rewards",
        element: <LevelRewards />,
    },
    {
        path: "/rewards/daily-limit",
        element: <DailyLimit />,
    },
    {
        path: "/events/tournaments",
        element: <Tournaments />,
    },
    {
        path: "/events/tournaments/add",
        element: <NewTournament />,
    },
    // {
    //     path: "/events/cash-tournaments/add",
    //     element: <NewCashTournament />,
    // },
    // {
    //     path: "/events/cash-tournaments/:tournamentId",
    //     element: <CashTournamentPayment/>
    // },
    // {
    //     path: "/events/cash-tournaments",
    //     element: <CashTournaments />
    // },
    {
        path: "/game/customization",
        element: <Customization />
    },
    // {
    //     path: "/events/cash-tournaments/edit/:tournamentId",
    //     element: <EditCashTournament />,
    // },
    {
        path: "/events/tournaments/edit/:tournamentId",
        element: <EditTournament />,
    },
    {
        path: "/cash-rewards/set-allocations",
        element: <SetAllocations />,
    },
    {
        path: "/cash-rewards/top-up-balance",
        element: <TopUpBalance />,
    },
    {
        path: "/analytics",
        // element: <Analytics />,
        element: <Games />,
    },
    {
        path: "/rewards",
        // element: <Rewards />,
        element: <Games />,
    },
    {
        path: "/rewards/manage-reward-pool",
        // element: <ManageRewardPool />,
        element: <Games />,
    },
    {
        path: "/rewards/set-region-factors",
        // element: <SetRegionFactors />,
        element: <Games />,
    },
    {
        path: "/rewards/set-limits",
        // element: <SetLimits />,
        element: <Games />,
    },
    {
        path: "/arena",
        // element: <Arena />,
        element: <Games />,
    },
    {
        path: "/studio/settings",
        element: <StudioSettings />,
    },
    {
        path: "/user/settings",
        element: <UserSettings />,
    },
    {
        path: "/support",
        element: <Games />,
    },
    {
        path: "/result",
        element: <Result />,
    },
];
