import {
    Breadcrumb,
    Button,
    Col,
    InputNumber,
    message,
    Popconfirm,
    Popover,
    Row,
    Space,
    Table,
    Spin
} from "antd";
import PageContent from "../../../../components/PageContent";
import Layout from "../../../../components/Layout";
import { EditOutlined, InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import PageHeader from "../../../../components/PageHeader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { regionFactors } from "../../../../store/slices/games";


export default function RegionFactors() {
    const { ui } = useSelector((state: GlobalState) => state);
    const { selectedGame } = ui;
    const [list, setList] = useState<Country[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const getList = async () => {
        if (selectedGame) {
            setLoading(true);
    
            const { success, result } = await regionFactors.List(
                +selectedGame.id
            );
            if (success) {                
                setList(result);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedGame) {
            getList();
        }
    }, [selectedGame]);



    type Country = { id: number, active: boolean, region_factor: number, country: CountryDetail, game: object }

    type CountryDetail = { id: number, active: boolean, code: string, name: "string" }

    const dataSource = list.length>0 ? list.map((c: Country) => {
        const { country, region_factor } = c;
        return {
            key: country?.id || "",
            region: country?.name || "",
            factor: region_factor,
            ...c
        };
    }):[];

    const updateRegionFactor = async (countryItem: any) => {
        try {
            const { success } = await regionFactors.Edit({
                game_id: countryItem.game.id,
                country_id: countryItem.country.id,
                region_factor: countryItem.region_factor,
            })

            if (success) {
                await getList();
                message.success("Successful");
            } else {
                message.error("Something went wrong. Please try again later.")
            }
        } catch (error) {
            message.error("Something went wrong. Please try again later.")
        }
       
    }


    const changeFactor = (c: any, a: number) => {
        const country = dataSource.find(i => i.id === c.id);
        const countryIndex = country ? dataSource.indexOf(country) : -1;
        const countryList = [...dataSource];
        countryList[countryIndex] = { ...c, region_factor: a || c.region_factor }

        setList(countryList);

    }

    // const dataSource22 = countries.map((country) => {
    //     return {
    //         key: country.numericCode,
    //         region: country.name,
    //         factor: 1.0,
    //         actions: (
    //             <>
    //                 <Button type="default" size="small" icon={<EditOutlined />}>
    //                     Edit
    //                 </Button>
    //             </>
    //         ),
    //     };
    // });

    return (
        <Layout>
            <PageHeader>
                <Row style={{ width: "100%" }}>
                    <Col span={12}>
                        <Breadcrumb
                            items={[
                                {
                                    title: (
                                        <Link to="/game/dashboard" state={selectedGame}>
                                            {selectedGame?.name}
                                        </Link>
                                    ),
                                },
                                { title: "Region factors" },
                            ]}
                        />
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <Popover
                            placement="leftTop"
                            content={
                                <p style={{ width: 300 }}>
                                    Region factors helps you optimize your
                                    retention cost for different regions. To
                                    give an example when you Whatever amount you
                                    enter as a Cash Point reward in the
                                    dashboard, it will be shown to the end user
                                    after being multiplied by their region
                                    factor.
                                </p>
                            }
                            title=""
                        >
                            <Button
                                type="default"
                                icon={<InfoCircleOutlined />}
                            >
                                Learn more
                            </Button>
                        </Popover>
                    </Col>
                </Row>
            </PageHeader>
            <PageContent>
                {loading ? <div style={{display:"flex", width:"100%", height:"100%", justifyContent:"center", alignItems:"center", minHeight:"500px"}}><Spin  indicator={<LoadingOutlined style={{fontSize:"3rem"}}/>}/> </div>: <Table
                    size="large"
                    dataSource={dataSource}
                    columns={[
                        {
                            title: (
                                <h3 style={{ color: "black", margin: 0 }}>
                                    Region
                                </h3>
                            ),
                            dataIndex: "region",
                            key: "region",
                        },
                        {
                            title: (
                                <h3 style={{ color: "black", margin: 0 }}>
                                    Factor
                                </h3>
                            ),
                            dataIndex: "factor",
                            key: "factor",
                            width: 100,
                            sorter: (a: any, b: any) => a.factor - b.factor,
                            render: (_, record) => {
                                return <>{record.factor}</>;
                            },
                        },
                        {
                            title: (
                                <h3 style={{ color: "black", margin: 0 }}>
                                    Actions
                                </h3>
                            ),
                            dataIndex: "actions",
                            key: "actions",
                            width: 100,
                            render: (_, record) => {
                                return (
                                    <>
                                        <Popconfirm
                                            placement="leftTop"
                                            onConfirm={() => {
                                                updateRegionFactor(record)
                                            }}
                                            okText="Update"
                                            icon={null}
                                            description={
                                                <Space direction="vertical">
                                                    <InputNumber
                                                        defaultValue={
                                                            record.factor
                                                        }
                                                        max={1}
                                                        min={0}
                                                        onChange={(e) => {
                                                            changeFactor(record, Number(e))
                                                        }}
                                                    />
                                                    {" "}
                                                </Space>
                                            }
                                            title={
                                                <span
                                                    style={{ color: "black" }}
                                                >
                                                    {`Edit region factor of ${record.region}`}
                                                </span>
                                            }
                                            trigger={"click"}
                                        >
                                            <Button
                                                type="default"
                                                size="small"
                                                icon={<EditOutlined />}
                                            >
                                                Edit
                                            </Button>
                                        </Popconfirm>
                                    </>
                                );
                            },
                        },
                    ]}
                    pagination={false}
                />}
               
            </PageContent>
        </Layout>
    );
}
