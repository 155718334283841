import { Breadcrumb, Button, Card, Col, Input, Popover, Row } from "antd";
import Layout from "../../../../components/Layout";
import PageContent from "../../../../components/PageContent";
import PageHeader from "../../../../components/PageHeader";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { rewardLimits } from "../../../../store/slices/games";
import { InfoCircleOutlined } from "@ant-design/icons";
import { coinDivider } from "../../../../utils/constants";

export default function DailyLimit() {
    const { ui } = useSelector((state: GlobalState) => state);
    const { selectedGame } = ui;
    const [val, setVal] = useState<number>(0);
    const [coin, setCoin] = useState<number>(300);
    const [dailyLimits, setDailyLimits] = useState([]);

    const getLimits = async () => {
        if (selectedGame) {
            const { success, total_count, result } = await rewardLimits.List(
                +selectedGame.id
            );

            result && setDailyLimits(result);

            console.log("DAILY LIMIT", { success, total_count, result });
        }
    };

    const saveDailyLimit = async () => {
        if (selectedGame) {
            if (dailyLimits.length) {
                // const { success, result } = await rewardLimits.Update(
                //     dailyLimits[0]?.id as number,
                //     +selectedGame.id,
                //     coin
                // );
                // console.log("UPDATED : ", success, result);
            } else {
                const { success, result } = await rewardLimits.Add(
                    +selectedGame?.id,
                    coin
                );
                if (success) {
                    getLimits();
                }
                console.log("ADDED : ", success, result);
            }
        }
    };

    useEffect(() => {
        coin && setVal(coin / coinDivider);
    }, [coin]);

    useEffect(() => {
        selectedGame && getLimits();
    }, [selectedGame]);

    return (
        <Layout>
            <PageHeader>
                <Breadcrumb
                    items={[
                        {
                            title: (
                                <Link to="/game/dashboard">
                                    {selectedGame?.name}
                                </Link>
                            ),
                        },
                        { title: "Daily limit" },
                    ]}
                />

                <Popover
                    placement="leftTop"
                    content={
                        <p style={{ width: 300 }}>
                            From the Daily Limit section, you can set a daily
                            reward limit for the game you want.
                        </p>
                    }
                    title=""
                >
                    <Button type="default" icon={<InfoCircleOutlined />}>
                        Learn more
                    </Button>
                </Popover>
            </PageHeader>
            <PageContent>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 100,
                    }}
                >
                    <div style={{ width: 400 }}>
                        <Card>
                            <h3 style={{ textAlign: "center", color: "black" }}>
                                Set daily limit
                            </h3>
                            <Input
                                accept="number"
                                size="large"
                                placeholder="0"
                                suffix={
                                    "$"
                                    // <img
                                    //     src="/assets/images/coin.png"
                                    //     width="20"
                                    // />
                                }
                                defaultValue={coin}
                                onChange={(e) => {
                                    setCoin(+e.target.value);
                                    // setVal(+e.target.value / 400);
                                }}
                            />
                            <br />
                            <br />
                            <Row>
                                <Col span={12}>Value</Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    ${val}
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Button
                                block
                                size="large"
                                type={"primary"}
                                disabled={!val}
                                onClick={saveDailyLimit}
                            >
                                Save
                            </Button>
                        </Card>
                    </div>
                </div>
            </PageContent>
        </Layout>
    );
}
