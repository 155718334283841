import { filterGames } from "../../store/slices/games";
import { useDispatch } from "react-redux";
import { Input } from "antd";

export default function GameSearchBox() {
    const dispatch = useDispatch();

    return (
        <div className="GameSearchBox">
            <div>
                <Input
                    size="large"
                    placeholder="Search for a game"
                    style={{ width: 400 }}
                    onChange={(e) => {
                        dispatch(filterGames(e.target.value));
                    }}
                />
            </div>
        </div>
    );
}
