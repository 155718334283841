import { generateApiKey, getApiKeys } from "../store/slices/studio";
import { message } from "antd";
import { useSelector } from "react-redux";

const useKeyGen = () => {
    const { user } = useSelector((state: GlobalState) => state.auth);

    const createKey = async (keyNick: string) => {
        if (keyNick && keyNick !== "") {
            const { success, result } = await generateApiKey({ name: keyNick });
            return { success, result };
        } else {
            message.error("Please type a nickname for the new key");
            return { success: false, message: "Key Nick is empty!." };
        }
    };

    const getKeyList = async () => {
        const filter = { studio_id: user?.studio?.id, active: true };
        const { result } = await getApiKeys({
            filter,
        });
        return result;
    };

    const deleteKey = async () => {
        const success = true;
        return success;
    };

    return { createKey, getKeyList, deleteKey };
};

export default useKeyGen;
