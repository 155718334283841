export const themeOverrideValues = {
    token: {
        colorPrimary: "#f1406f",
        fontFamily: "LufgaRegular",
        colorText: "#919297",
        colorBgTextHover: "#F8F8F8",
        colorLinkHover: "black",
    },
    components: {
        Input: {
            colorBorder: "#f0f0f0",
            colorPrimaryHover: "#f0f0f0",
            colorPrimaryActive: "#f0f0f0",
            colorFillAlter: "red",
        },
        Message: {
            fontFamily: "LufgaRegular",
        },
    },
};
