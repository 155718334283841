import { Button, Form, Input, Space, message } from "antd";
import { Dispatch, SetStateAction } from "react";
import { Rule } from "antd/lib/form";
import { resetUserPassword } from "../../../../../store/slices/auth";

export default function ResetPassword({
    state,
    setState,
}: {
    state: string;
    setState: Dispatch<SetStateAction<string>>;
}) {
    const validatePassword = (_: any, value: string) => {
        const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
        if (!specialCharacterRegex.test(value)) {
            return Promise.reject(
                "Your password must have at least one special character!"
            );
        }
        if (value.length < 8) {
            return Promise.reject(`Password must be at least 8 characters!`);
        }

        return Promise.resolve();
    };

    const validatePasswordRepeat: Rule = ({ getFieldValue }: any) => ({
        validator(_, value: string) {
            if (!value || getFieldValue("new_password") === value) {
                return Promise.resolve();
            }

            return Promise.reject("Passwords do not match!!");
        },
    });

    const saveNewPassword = async (values: any) => {
        const { success } = await resetUserPassword({
            password: values.current_password,
            new_password: values.new_password,
        });

        if (success) {
            message.success("Password update successfully.");
        }
    };

    return (
        <>
            <Form layout="vertical" onFinish={saveNewPassword}>
                <Form.Item
                    name="current_password"
                    label="Current password"
                    rules={[
                        {
                            required: true,
                            message: "Please input your current password!",
                        },
                    ]}
                >
                    <Input type="password" placeholder="Current password" />
                </Form.Item>
                <Form.Item
                    name="new_password"
                    label="New password"
                    rules={[
                        {
                            required: true,
                            message: "Please input your new password!",
                        },
                        { validator: validatePassword },
                    ]}
                >
                    <Input type="password" placeholder="New password" />
                </Form.Item>
                <Form.Item
                    name="new_password_repeat"
                    label="New password"
                    rules={[
                        {
                            required: true,
                            message: "Please input your new password again!",
                        },
                        validatePasswordRepeat,
                    ]}
                >
                    <Input type="password" placeholder="New password" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" block htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
            <Button
                block
                onClick={() => {
                    setState("studioSettings");
                }}
            >
                Cancel
            </Button>
        </>
    );
}
