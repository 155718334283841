import { combineReducers, configureStore } from "@reduxjs/toolkit";
import gamesReducer from "./slices/games";
import uiReducer from "./slices/ui";
import authReducer from "./slices/auth";
import { injectStore } from "../utils/api";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["games"],
};

const rootReducer = combineReducers({
    games: gamesReducer,
    ui: uiReducer,
    auth: authReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
    reducer: persistedReducer,
});
const persistor = persistStore(store);
injectStore(store);
export { persistor };
export type AppDispatch = typeof store.dispatch;
