import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../../components/Layout";
import PageHeader from "../../../../components/PageHeader";
import Wrapper from "../../../../components/FormElements/Wrapper";
import { useEffect, useState } from "react";
import { CategoryList } from "../../../../config/categoryList";
import { generateSlug } from "../../../../utils/helpers";
import {
    Button,
    Form,
    Input,
    message,
    Select,
    Upload,
    Switch,
    Breadcrumb,
} from "antd";
import {
    CheckCircleOutlined,
    ArrowLeftOutlined,
    LoadingOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import type { RcFile } from "antd/es/upload/interface";
import useForm from "antd/es/form/hooks/useForm";
import { updateGame } from "../../../../store/slices/games";

import "./style.css";

const { TextArea } = Input;
const { Option } = Select;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

export default function EditGame() {
    const [form] = useForm();
    const navigate = useNavigate();
    const location = useLocation();

    const [iconUrl, setIconUrl] = useState<string>();
    const [iconLoading, setIconLoading] = useState(false);
    const [bannerUrl, setBannerUrl] = useState<string>();
    const [bannerLoading, setBannerLoading] = useState(false);
    const [, setButtonDisabled] = useState(true);
    const [androidPlatformSwitch, setAndroidPlatformSwitch] = useState(false);
    const [IOsPlatformSwitch, setIOsPlatformSwitch] = useState(false);
    const [formData, setFormData] = useState();

    useEffect(() => {
        if (location.state) {
            const fixedData = fixFormData(location.state);
            setFormData(fixedData); // fixedFormData
        }
        // eslint-disable-next-line
    }, [location.state]);

    function fixFormData(oldData: {}) {
        setIconUrl(location.state.logo);
        setBannerUrl(location.state.background_img);
        let fixedData = {
            ...location.state,
            icon: location.state.logo,
            banner: location.state.background_img,
            category_ids: location.state.categories.map((cat: any) => cat.id),
            android: location.state.config?.android ? true : false,
            ios: location.state.config?.ios ? true : false,
            android_package_name: location.state?.config?.android?.package_name,
            android_deeplink: location.state.config?.android?.deeplink,
            android_store_page_url:
                location.state.config?.android?.store_page_url,
            ios_package_name: location.state.config?.ios?.package_name,
            ios_deeplink: location.state.config?.ios?.deeplink,
            ios_store_page_url: location.state.config?.ios?.store_page_url,
        };

        return fixedData;
    }

    function checkValidation() {
        setButtonDisabled(
            form.getFieldsError().filter(({ errors }) => errors.length).length >
                0
        );
    }

    function fixValues() {
        const values = { ...form.getFieldsValue()};
        if (iconUrl && iconUrl !== location.state.logo) {
            values.logo = iconUrl;
        }
        if (bannerUrl && bannerUrl !== location.state.background_img) {
            values.background_img = bannerUrl;
        }
        values.config = {};
        delete values.banner;
        delete values.icon;
        if (values.android) {
            values.config.android = {
                package_name: values.android_package_name,
                deeplink: values.android_deeplink,
                store_page_url: values.android_store_page_url,
            };
        }
        if (values.ios) {
            values.config.ios = {
                package_name: values.ios_package_name,
                deeplink: values.ios_deeplink,
                store_page_url: values.ios_store_page_url,
            };
        }
        delete values.android_package_name;
        delete values.android_deeplink;
        delete values.android_store_page_url;
        delete values.android;

        delete values.ios_package_name;
        delete values.ios_deeplink;
        delete values.ios_store_page_url;
        delete values.ios;

        const generatedSlug =values.name?generateSlug(values.name):"";
        values.slug = generatedSlug;

        return values;
    }

    const onFinish = async () => {
        /// fix values
        const vals = fixValues();
        /// save game into the database
        const { success } = await updateGame({
            gameId: location.state.id,
            data: vals,
        });
        /// and navigate to /games if success === true
        if (success) {
            message.success("Game saved successfully");
            navigate("/games/manage-games");
        } else {
            message.error("Please fill required fields correctly!");
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        message.error("Please fill required fields!");
    };

    if (!formData) return null;

    return (
        <Layout>
            <PageHeader>
                {/* <Link to="/games"> */}
                <Breadcrumb
                    items={[
                        { title: <Link to="/">Home</Link> },
                        { title: <Link to="/games/manage-games">Games</Link> },
                        { title: "Edit game" },
                    ]}
                />
                {/* <h2 style={{ fontWeight: 200 }}>
                        <ArrowLeftOutlined
                            style={{ color: "#f1406f", marginRight: 30 }}
                        />{" "}
                        Edit Game
                    // </h2> */}
                {/* </Link> */}

                <Button
                    key="submit"
                    htmlType="submit"
                    size="large"
                    type="primary"
                    icon={<CheckCircleOutlined />}
                    // disabled={buttonDisabled}
                    onClick={onFinish}
                >
                    Save
                </Button>
            </PageHeader>
            <Wrapper>
                <Form
                    size="large"
                    form={form}
                    name="newGameForm"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    style={{ maxWidth: 800 }}
                    onChange={checkValidation}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={formData}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please input game name!",
                            },
                        ]}
                    >
                        <Input placeholder="Type name here.." />
                    </Form.Item>

                    <Form.Item
                        label="Game Icon"
                        name="icon"
                        help={"1024x1024, JPEG or JPG"}
                        rules={[
                            {
                                required: false,
                                message: "Please upload game icon!",
                            },
                        ]}
                    >
                        <Upload
                            name="icon"
                            listType="picture-card"
                            showUploadList={false}
                            accept="image/jpeg"
                            beforeUpload={(info) => {
                                getBase64(info, (url) => {
                                    setIconLoading(false);
                                    setIconUrl(url);
                                    return;
                                });
                            }}
                        >
                            {iconUrl ? (
                                <img
                                    src={iconUrl}
                                    alt="Game Icon"
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                <div>
                                    {iconLoading ? (
                                        <LoadingOutlined />
                                    ) : (
                                        <PlusOutlined />
                                    )}
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="Game Banner"
                        name="banner"
                        help={"1000x600, JPEG or JPG"}
                        rules={[
                            {
                                required: false,
                                message: "Please upload game banner!",
                            },
                        ]}
                    >
                        <Upload
                            className="gameBannerUploader"
                            name="banner"
                            listType="picture-card"
                            showUploadList={false}
                            accept="image/jpeg"
                            beforeUpload={(info) => {
                                getBase64(info, (url) => {
                                    setBannerLoading(false);
                                    setBannerUrl(url);
                                    return;
                                });
                            }}
                        >
                            {bannerUrl ? (
                                <img
                                    src={bannerUrl}
                                    alt="Game Banner"
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                <div>
                                    {bannerLoading ? (
                                        <LoadingOutlined />
                                    ) : (
                                        <PlusOutlined />
                                    )}
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        name="android"
                        label="Android"
                        valuePropName="checked"
                    >
                        <Switch onChange={setAndroidPlatformSwitch}  />
                    </Form.Item>

                    <Form.Item
                        name="android_package_name"
                        label="Package Name"
                        rules={[
                            {
                                required: androidPlatformSwitch,
                                message: "Please input Android package name!",
                            },
                        ]}
                    >
                        <Input placeholder="com.example.app123" />
                    </Form.Item>

                    <Form.Item
                        name="android_deeplink"
                        label="Deeplink"
                        rules={[
                            {
                                required: androidPlatformSwitch,
                                message: "Please input Android deeplink!",
                            },
                        ]}
                    >
                        <Input placeholder="https://play.google.com/store/apps/details?id=com.example.app123" />
                    </Form.Item>

                    <Form.Item
                        name="android_store_page_url"
                        label="Store Page URL"
                        rules={[
                            {
                                required: androidPlatformSwitch,
                                message: "Please input Android store page url!",
                            },
                        ]}
                    >
                        <Input placeholder="https://play.google.com/store/apps/details?id=com.example.app123" />
                    </Form.Item>

                    <Form.Item name="ios" label="IOS" valuePropName="checked">
                        <Switch onChange={setIOsPlatformSwitch} />
                    </Form.Item>

                    <Form.Item
                        name="ios_package_name"
                        label="Package Name"
                        rules={[
                            {
                                required: IOsPlatformSwitch,
                                message: "Please input IOs package name!",
                            },
                        ]}
                    >
                        <Input placeholder="com.example.app123" />
                    </Form.Item>

                    <Form.Item
                        name="ios_deeplink"
                        label="Deeplink"
                        rules={[
                            {
                                required: IOsPlatformSwitch,
                                message: "Please input IOs deeplink!",
                            },
                        ]}
                    >
                        <Input placeholder="https://play.google.com/store/apps/details?id=com.example.app123" />
                    </Form.Item>

                    <Form.Item
                        name="ios_store_page_url"
                        label="Store Page URL"
                        rules={[
                            {
                                required: IOsPlatformSwitch,
                                message: "Please input IOs store page url!",
                            },
                        ]}
                    >
                        <Input placeholder="https://play.google.com/store/apps/details?id=com.example.app123" />
                    </Form.Item>
                </Form>
            </Wrapper>
        </Layout>
    );
}
