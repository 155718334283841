import { Link, useNavigate } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "./style.css";
import { Avatar, Card, Switch, Popconfirm, message } from "antd";
import {
    deleteGame,
    getStudioGames,
    updateGame,
    updateGames,
} from "../../../../store/slices/games";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
const { Meta } = Card;

export default function GameBox({ gameInfo }: { gameInfo: Game }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state: GlobalState) => state.auth);

    return (
        <Card
            cover={
                // <Link to={`/game/${gameInfo.slug}`} state={gameInfo}>
                <Link to={`/game/dashboard`} state={gameInfo}>
                    <div
                        className="cover"
                        style={{
                            backgroundImage: `url(${gameInfo.background_img})`,
                            border: "1px solid #f0f0f0",
                            borderBottom: "none",
                        }}
                    ></div>
                </Link>
            }
            actions={[
                <EditOutlined
                    key="edit"
                    onClick={() => {
                        navigate(`/game/edit/${gameInfo.slug}`, {
                            state: gameInfo,
                        });
                    }}
                />,
                <Popconfirm
                    title="Delete the game"
                    description="Are you sure to delete this game?"
                    onConfirm={async () => {
                        const resp = await deleteGame({ gameId: +gameInfo.id });
                        const { success } = resp;

                        if (success) {
                            message.success(`${gameInfo.name} deleted`);
                            const { success, result } = await getStudioGames({
                                filter: {
                                    studio_id: user?.studio?.id,
                                    active: true,
                                },
                            });

                            if (success) {
                                dispatch(updateGames(result));
                            }
                        } else {
                            message.error("Failed");
                        }
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined key="ellipsis" />
                </Popconfirm>,
            ]}
        >
            <Meta
                avatar={<Avatar src={gameInfo.logo} shape="square" size={64} />}
                title={
                    <Link to={`/game/dashboard`} state={gameInfo}>
                        <div style={{ fontSize: "1.2rem" }}>
                            {gameInfo.name}
                        </div>
                    </Link>
                }
                description={<GameBoxSettings gameInfo={gameInfo} />}
            />
        </Card>
    );
}

function GameBoxSettings({ gameInfo }: { gameInfo: Game }) {
    const [open, setOpen] = useState<boolean>(false);
    const [open_ios, setOpen_ios] = useState<boolean>(false);
    const [iosActive, setIosActive] = useState<boolean>(
        gameInfo?.is_ios ? true : false
    );
    const [androidActive, setAndroidActive] = useState<boolean>(
        gameInfo?.is_android ? true : false
    );
    const [editedGame, setEditedGame] = useState<Game>();

    // useEffect(() => {
    //     setEditedGame(gameInfo);
    // }, [gameInfo]);

    useEffect(() => {
        editedGame && saveEditedGame(editedGame);
    }, [editedGame]);

    const saveEditedGame = async (newValues: Game) => {
        const resp = await updateGame({
            gameId: +gameInfo.id,
            data: {
                category_ids: newValues.category_ids,
                config: newValues.config,
                description: newValues.description,
                name: newValues.name,
                slug: newValues.slug,
                is_android: newValues.is_android,
                is_ios: newValues.is_ios,
            },
        });

        if (resp.success) {
            message.success("Game edited successfully");
        } else {
            message.error(resp.message);
        }
    };

    const togglePlatform = async (platform: string, value: boolean) => {
        if (platform === "ios") {
            setEditedGame({ ...gameInfo, is_ios: value });
        }
        if (platform === "android") {
            setEditedGame({ ...gameInfo, is_android: value });
        }
    };

    return (
        <div className="GameBoxSettings">
            <div className="row">
                <div className="col">
                    <img
                        src="/assets/icons/android.svg"
                        width={15}
                        height={15}
                        style={{ marginRight: 10 }}
                        alt="button icon"
                    />{" "}
                    Android
                </div>
                <div className="col" style={{ paddingRight: 7 }}>
                    <Popconfirm
                        title="Warning"
                        description="You are disabling Ratic integration!"
                        open={open}
                        onConfirm={() => {
                            setAndroidActive(false);
                            setOpen(false);
                            togglePlatform("android", false);
                        }}
                        onCancel={() => {
                            setOpen(false);
                            setAndroidActive(true);
                        }}
                        okText="Proceed"
                        cancelText="Cancel"
                    >
                        <Switch
                            size="small"
                            onClick={(status) => {
                                if (status) {
                                    setAndroidActive(status);
                                    togglePlatform("android", true);
                                }
                                setOpen(!status);
                            }}
                            checked={androidActive}
                        />
                    </Popconfirm>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img
                        src="/assets/icons/ios.svg"
                        width={15}
                        height={15}
                        style={{ marginRight: 10 }}
                        alt="button icon"
                    />{" "}
                    IOS
                </div>
                <div className="col" style={{ paddingRight: 7 }}>
                    <Popconfirm
                        title="Warning"
                        description="You are disabling Ratic integration!"
                        open={open_ios}
                        onConfirm={() => {
                            setIosActive(false);
                            setOpen_ios(false);
                            togglePlatform("ios", false);
                        }}
                        onCancel={() => {
                            setOpen_ios(false);
                            setIosActive(true);
                        }}
                        okText="Proceed"
                        cancelText="Cancel"
                    >
                        <Switch
                            size="small"
                            onClick={(status) => {
                                if (status) {
                                    setIosActive(status);
                                    togglePlatform("ios", true);
                                }
                                setOpen_ios(!status);
                            }}
                            checked={iosActive}
                        />
                    </Popconfirm>
                </div>
            </div>
        </div>
    );
}
