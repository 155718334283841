import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { ROUTES } from "./config/routes";

import { App, ConfigProvider } from "antd";

import { themeOverrideValues } from "./config/themeOverride";

import "./stylesheets/global.css";

/// routes comes from ./config/routes 👇🏻
const router = createBrowserRouter(ROUTES);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
if (isMobile) {
    root.render(
        <>
            <div className="mobileDetected">
                <div className="contentContainer">
                    <h1>
                        Desktop recommended:
                        <br />
                        Unlock Ratic's full potential.
                    </h1>
                    <p>We recommend using a desktop device.</p>
                </div>
            </div>
        </>
    );
} else {
    root.render(
        <ConfigProvider theme={themeOverrideValues}>
            <App>
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <RouterProvider router={router} />
                    </PersistGate>
                </Provider>
            </App>
        </ConfigProvider>
    );
}
