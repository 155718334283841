import {
    Button,
    Col,
    Empty,
    Input,
    Popconfirm,
    Row,
    message,
    notification,
} from "antd";
import PageHeader from "../../../../components/PageHeader";
import GameSearchBox from "../../../../components/GameSearchBox";
import { Link } from "react-router-dom";
import PageContent from "../../../../components/PageContent";
import { PlusOutlined } from "@ant-design/icons";
import Layout from "../../../../components/Layout";
import KeyBox from "./components/keyBox";
import { useEffect, useState } from "react";
import { generateApiKey, getApiKeys } from "../../../../store/slices/studio";
import { useSelector } from "react-redux";
import useKeyGen from "../../../../hooks/useKeyGen";

export default function APIKeys() {
    const { user } = useSelector((state: GlobalState) => state.auth);
    const [apiKeyList, setApiKeyList] = useState<ApiKey[]>([]);
    const [newKeyNick, setNewKeyNick] = useState<string>("");
    const [api, contextHolder] = notification.useNotification();
    const { createKey, getKeyList } = useKeyGen();

    const apiKeys = async () => {
        const filter = { studio_id: user?.studio?.id, active: true };
        const resp = await getApiKeys({
            filter,
        });

        setApiKeyList(resp.result);
    };

    const generateKey = async () => {
        if (newKeyNick && newKeyNick !== "") {
            const resp = await createKey(newKeyNick);
            if (resp.success) {
                message.success("New key generated");
                setApiKeyList([...apiKeyList, resp.result]);
                api["warning"]({
                    message: "Warning",
                    description:
                        "Your secret can only be copied once, don't forget to write it down. If you leave or refresh the page you will not be able to copy it again.",
                    duration: 0,
                });
            }
        } else {
            message.error("Please type a nickname for the new key");
        }
    };

    useEffect(() => {
        apiKeys();
    }, []);

    return (
        <Layout>
            {contextHolder}
            <PageHeader>
                <Input
                    size="large"
                    placeholder="Search for a key"
                    style={{ width: 400 }}
                    onChange={(e) => {}}
                />
                <Popconfirm
                    title="Generate New Key"
                    description={
                        <Input
                            placeholder="Nickname"
                            onChange={(e) => {
                                setNewKeyNick(e.target.value);
                            }}
                        />
                    }
                    onConfirm={() => {
                        generateKey();
                    }}
                    okText="Generate"
                    cancelText="Cancel"
                >
                    <Button type="primary" icon={<PlusOutlined />} size="large">
                        Create new key
                    </Button>
                </Popconfirm>
            </PageHeader>
            <PageContent>
                {apiKeyList.length === 0 ? (
                    <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{ height: 200 }}
                        description={
                            <span>
                                <h2>There are no API keys</h2>
                                <p>You can generate a new one</p>
                            </span>
                        }
                    >
                        <Popconfirm
                            title="Generate New Key"
                            description={
                                <Input
                                    placeholder="Nickname"
                                    onChange={(e) => {
                                        setNewKeyNick(e.target.value);
                                    }}
                                />
                            }
                            onConfirm={() => {
                                generateKey();
                            }}
                            okText="Generate"
                            cancelText="Cancel"
                        >
                            <Button type="primary">Generate new key</Button>
                        </Popconfirm>
                    </Empty>
                ) : (
                    <Row gutter={[16, 16]}>
                        {apiKeyList.map((api_key, index) => {
                            return (
                                <Col span={6} key={index}>
                                    <KeyBox
                                        keyInfo={api_key}
                                        refreshList={apiKeys}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                )}
            </PageContent>
        </Layout>
    );
}
