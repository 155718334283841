import type { BadgeProps } from "antd";
import { Badge, Calendar, Spin } from "antd";
import type { Dayjs } from "dayjs";
import type { CellRenderInfo } from "rc-picker/lib/interface";
import { getTournamentsList, getCashTournamentsList } from "../../../store/slices/games";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { fixStatus } from "../../../utils/helpers";
import { useLocation } from "react-router-dom";
dayjs.extend(isBetween);

const getMonthData = (value: Dayjs) => {
    if (value.month() === 8) {
        return 1394;
    }
};

export default function GameCalendar() {
    const { user } = useSelector((state: GlobalState) => state.auth);
    const { selectedGame } = useSelector((state: GlobalState) => state.ui);
    const [tournamentList, setTournamentList] = useState<Tournament[]>([]);
    const location = useLocation();

    const getTournaments = async () => {
        const { success, total_count, result } = await getTournamentsList({
            filter: {
                studio_id: user?.studio?.id,
                game_id: location.state.id,
                active: true,
            },
        });
        if (success && total_count > 0) {
            setTournamentList(result);
        }
    };

    const getCashTournaments = async () => {
        const { success, total_count, result } = await getCashTournamentsList({
            filter: {
                studio_id: user?.studio?.id,
                game_uuid: selectedGame?.uuid,
                active: true,
            },
        });
        if (success && total_count > 0) {
            setTournamentList([...tournamentList, ...result]);
        }
    };


    const getAllTournaments = async () => {
        const body = {
            filter: {
                studio_id: user?.studio?.id,
                game_uuid: location.state.uuid,
                game_id: selectedGame?.id,
                active: true,
            },
        }
        let list: Tournament[] = [];
       if(selectedGame && location.state.id) Promise.all([getTournamentsList(body)
            // , getCashTournamentsList(body)
        ]).then((resp) => {
            resp.forEach(t => {
                if (t.success) {
                    list = [...list, ...t.result];
            }
            })
        }).then(() => {
            setTournamentList(list);
        })
    }

    useEffect(() => {
        getAllTournaments();
        // getTournaments().then(() => {
        //     console.log("mevcutta", tournamentList);
        //     getCashTournaments();
        // })

    }, [selectedGame]);


    const monthCellRender = (value: Dayjs) => {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    };

    const getDayEventList = (value: Dayjs) => {
        const todaysEvents: Tournament[] = [];
        if (tournamentList) {
            tournamentList.map((tournament: Tournament) => {
                const start_date = dayjs(tournament.raw_start_time||tournament.start_time);
                const end_date = dayjs(tournament.raw_end_time|| tournament.end_time);
                const today = value;
                const scope = start_date.day() === end_date.day() && start_date.month() === end_date.month() && start_date.year()===end_date.year() ? "[]" : "[)";
                const isBetween = today.isBetween(start_date, end_date,"date",scope);

                if (isBetween) {
                    todaysEvents.push(tournament);
                }
            });
        }

        return todaysEvents;
    };

    const dateCellRender = (value: Dayjs) => {
        const dayEventList = getDayEventList(value);
        return (
            <ul className="events">
                {dayEventList.map((item: Tournament) => {
                    const fixedStatus = fixStatus(
                        item.raw_start_time ? item.raw_start_time : item.start_time,
                        item.raw_end_time ? item.raw_end_time:item.end_time
                    );
                    const statusColor =
                        fixedStatus === "finished"
                            ? "error"
                            : fixedStatus === "ongoing"
                            ? "success"
                            : "warning";

                    return (
                        <li key={item.id}>
                            <Badge
                                size="small"
                                status={statusColor as BadgeProps["status"]}
                                text={item.name}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    };

    const cellRender = (current: Dayjs, info: CellRenderInfo<Dayjs>) => {
        if (info.type === "date") return dateCellRender(current);
        if (info.type === "month") return monthCellRender(current);
        return info.originNode;
    };
    if (!tournamentList) return <Spin />;
    return <Calendar cellRender={cellRender} />;
}
