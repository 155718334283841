import { Button, Form, Input, message } from "antd";
import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editStudio } from "../../../../../store/slices/studio";
import { setUser } from "../../../../../store/slices/auth";

export default function StudioSettings({
    state,
    setState,
}: {
    state: string;
    setState: Dispatch<SetStateAction<string>>;
}) {
    const { user } = useSelector((state: GlobalState) => state.auth);
    const dispatch = useDispatch();

    const saveInfo = async (values: {
        name: string;
        url: string;
        description: string;
    }) => {
        const { success, user } = await editStudio(values);
        if (success) {
            dispatch(setUser(user));
            message.success("Studio info updated successfully");
        }
    };

    return (
        <>
            <Form
                layout="vertical"
                onFinish={saveInfo}
                initialValues={{
                    name: user?.studio.name,
                    url: user?.studio.url,
                    description: user?.studio.description,
                }}
            >
                <Form.Item name="name" label="Studio name">
                    <Input placeholder="Studio Name" />
                </Form.Item>
                {/* <Form.Item name="description" label="Studio description">
                    <Input placeholder="Studio description" />
                </Form.Item> */}
                <Form.Item>
                    <Button type="primary" block htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
            <Button
                type="default"
                block
                onClick={() => {
                    setState("resetPassword");
                }}
            >
                Reset password
            </Button>
        </>
    );
}
