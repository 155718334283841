import { Button, Col, Row } from "antd";
import "./style.css";
import LoginForm from "./components/LoginForm";

export default function Login() {
    return (
        <Row>
            <Col
                span={12}
                style={{
                    background:
                        'url("/assets/images/login-bg.png") 61px center / contain rgb(249,197,228)  no-repeat',
                }}
            ></Col>
            <Col span={12}>
                <div className="loginFormWarpper">
                    <div className="loginFormContainer">
                        <div className="logoContainer">
                            <img
                                src="/assets/images/logo_ratic.png"
                                width={180}
                                height={80}
                                alt="Ratic Logo"
                            />
                        </div>
                        <div className="titleContainer">
                            Build LiveOps 15x faster!
                        </div>
                        <div className="formContainer">
                            <LoginForm />
                            <a
                                href="https://ratic.io/get-started"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button
                                    block
                                    size="large"
                                    type="primary"
                                    style={{
                                        background: "#FFF0F1",
                                        color: "#EE3D6F",
                                    }}
                                >
                                    Get started
                                </Button>
                            </a>
                        </div>
                    </div>

                    <div className="loginLinks">
                        <div className="linksContainer">
                            <a
                                href="https://ratic.io/legal-studio/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms & Conditions
                            </a>
                            |
                            <a
                                href="https://ratic.io/legal-studio/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Privacy Policy
                            </a>
                            |
                            <a
                                href="https://wa.me/13024922530"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Support
                            </a>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
}
