import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Input,
    Row,
    Typography,
    message,
} from "antd";
import Layout from "../../../../components/Layout";
import PageContent from "../../../../components/PageContent";
import PageHeader from "../../../../components/PageHeader";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
const { Text } = Typography;

export default function TopUpBalance() {
    const [coinInput, setCoinInput] = useState<number>();
    const [val, setVal] = useState<number>(0);
    const [fee, setFee] = useState<number>(0);
    const [cost, setCost] = useState<number>(0);
    const FEE_PERCENTAGE = 15;

    useEffect(() => {
        if (coinInput) {
            // const value = coinInput / COIN_VAL;
            const value = coinInput;
            const calculatedFee = value * (FEE_PERCENTAGE / 100);
            setVal(value);
            setFee(calculatedFee);
        } else {
            setVal(0);
            setFee(0);
            setCost(0);
        }
    }, [coinInput]);

    useEffect(() => {
        setCost(val + fee);
    }, [val]);

    return (
        <Layout>
            <PageHeader>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/games/manage-games">Home</Link>,
                        },
                        { title: "Top up balance" },
                    ]}
                />
            </PageHeader>
            <PageContent>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 100,
                    }}
                >
                    <div style={{ width: 400 }}>
                        <Card>
                            <h3 style={{ textAlign: "center", color: "black" }}>
                                Buy Points
                            </h3>
                            <Input
                                accept="number"
                                size="large"
                                placeholder="0"
                                suffix={
                                    "$"
                                    // <img
                                    //     src="/assets/images/coin.png"
                                    //     width="20"
                                    // />
                                }
                                onChange={(e) => {
                                    setCoinInput(+e.target.value);
                                }}
                            />
                            <br />
                            <br />
                            {/* <Row>
                                <Col span={12}>Value</Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    ${val}
                                </Col>
                            </Row> */}
                            <br />
                            <Row>
                                <Col span={12}>Fee</Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    ${fee}
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Button
                                block
                                size="large"
                                type={cost !== 0 ? "primary" : "default"}
                                onClick={() => {
                                    message.error(
                                        "This area requires private access"
                                    );
                                }}
                            >
                                Pay ${cost}
                            </Button>
                        </Card>
                    </div>
                </div>
            </PageContent>
        </Layout>
    );
}
