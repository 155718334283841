import {
    Breadcrumb,
    Button,
    Form,
    Input,
    Popconfirm,
    Popover,
    Space,
    Table,
    message as popupMessage,
} from "antd";
import Layout from "../../../../components/Layout";
import PageContent from "../../../../components/PageContent";
import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import PageHeader from "../../../../components/PageHeader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { levelRewards } from "../../../../store/slices/games";

export default function LevelRewards() {
    const [addMode, setAddMode] = useState<boolean>(false);
    const { ui } = useSelector((state: GlobalState) => state);
    const { selectedGame } = ui;
    const [list, setList] = useState<object[]>([]);
    const [newRow, setNewRow] = useState<LevelReward>({
        game_id: 0,
        level: 0,
        xp_reward: 0,
        coin_reward: 0,
        starting_xp: 0,
    });

    useEffect(() => {
        if (selectedGame) {
            getList();
        }
    }, [selectedGame]);

    const getList = async () => {
        if (selectedGame) {
            const { success, result } = await levelRewards.List(
                +selectedGame.id
            );
            if (success) {
                setList(result);
            }
        }
    };

    const addRewardLevel = async (data: LevelReward) => {
        const {success, message} = await levelRewards.Add(data);

        if (success) {
            popupMessage.success("Level row added successfully.");
            getList();
        } else {
            popupMessage.error(message);
        }

    };

    function saveRow() {
        if (selectedGame) {
            if (list.length > 0 && list.findIndex((l:any) => l.level === newRow.level) !== -1) {
                popupMessage.warning("Level is already added");
                return;
            }
            
            if (newRow.level <= 1) {
                popupMessage.warning("Level must be bigger than 1");
                return;
            }
            if (newRow.starting_xp <= 0 || newRow.xp_reward <= 0) {
                popupMessage.warning("XP value must be positive");
                return;
            }
            if (newRow.coin_reward <= 0) {
                popupMessage.warning("Cash Point value must be positive");
                return;
            }
            const newRowData = { ...newRow, game_id: +selectedGame?.id };
            addRewardLevel(newRowData);
            setAddMode(false);
        }
    }

    const deleteLevelRow = async (row: any) => {
        const { success } = await levelRewards.Delete({
            ...row,
        });
        if (success) {
            popupMessage.success("Level row deleted successfully.");
            getList();
        }
    };

    function addNewRow() {
        setAddMode(true);
    }

    const updateRow = async (values: any) => {

        if (values.level <= 1) {
            popupMessage.warning("Level must be bigger than 1");
            return;
        }
        if (values.starting_xp <= 0 || values.xp_reward <= 0) {
            popupMessage.warning("XP value must be positive");
            return;
        }
        if (values.coin_reward <= 0) {
            popupMessage.warning("Cash Point value must be positive");
            return;
        }
        const { success, message } = await levelRewards.Edit(values);
        if (success) {
             //getList();
            window.location.reload();
            popupMessage.success("Level row updated successfully.");
        } else {
            popupMessage.error(message);
        }
    };

    return (
        <Layout>
            <PageHeader>
                <Breadcrumb
                    items={[
                        {
                            title: (
                                <Link to="/game/dashboard">
                                    {selectedGame?.name}
                                </Link>
                            ),
                        },
                        { title: "Level rewards" },
                    ]}
                />

                <Popover
                    placement="leftTop"
                    content={
                        <p style={{ width: 300 }}>
                            Level Rewards allow you to assign and organize
                            rewards for your games in a level-based way. You can
                            arrange three different reward types from here as
                            you wish.
                        </p>
                    }
                    title=""
                >
                    <Button type="default" icon={<InfoCircleOutlined />}>
                        Learn more
                    </Button>
                </Popover>
            </PageHeader>
            <PageContent>
                <Table
                    rowKey={"id"}
                    columns={[
                        {
                            title: (
                                <h3 style={{ color: "black", margin: 0 }}>
                                    Level
                                </h3>
                            ),
                            dataIndex: "level",
                            key: "level",
                            sorter: (a: any, b: any) => a.level - b.level,
                        },
                        {
                            title: (
                                <h3 style={{ color: "black", margin: 0 }}>
                                    Starting XP
                                </h3>
                            ),
                            dataIndex: "starting_xp",
                            key: "starting_xp",
                        },
                        {
                            title: (
                                <h3 style={{ color: "black", margin: 0 }}>
                                    Rewards
                                </h3>
                            ),
                            key: "rewards",
                            dataIndex: "rewards",
                            render: (_, record: any) => {
                                return (
                                    <ul>
                                        <li>
                                            Cash Points :{" "}
                                            {parseInt(record?.coin_reward)}
                                        </li>
                                        <li>XP : {record?.xp_reward}</li>
                                    </ul>
                                );
                            },
                        },
                        // {
                        //     title: (
                        //         <h3 style={{ color: "black", margin: 0 }}>
                        //             Ratic Points
                        //         </h3>
                        //     ),
                        //     dataIndex: "coin_reward",
                        //     key: "coin_reward",
                        //     sorter: (a: any, b: any) =>
                        //         a.coin_reward - b.coin_reward,
                        // },
                        // {
                        //     title: (
                        //         <h3 style={{ color: "black", margin: 0 }}>
                        //             Starting XP
                        //         </h3>
                        //     ),
                        //     dataIndex: "starting_xp",
                        //     key: "starting_xp",
                        //     sorter: (a: any, b: any) =>
                        //         a.starting_xp - b.starting_xp,
                        // },
                        // {
                        //     title: (
                        //         <h3 style={{ color: "black", margin: 0 }}>
                        //             XP
                        //         </h3>
                        //     ),
                        //     dataIndex: "xp_reward",
                        //     key: "xp_reward",
                        //     sorter: (a: any, b: any) =>
                        //         a.xp_reward - b.xp_reward,
                        // },
                        {
                            title: (
                                <h3 style={{ color: "black", margin: 0 }}>
                                    Actions
                                </h3>
                            ),
                            dataIndex: "actions",
                            key: "actions",
                            width: 200,
                            render: (_, record: any) => {
                                return (
                                    <>
                                        <Popover
                                            placement="left"
                                            content={
                                                <>
                                                    <Form
                                                        initialValues={record}
                                                        labelCol={{ span: 9 }}
                                                        onFinish={(
                                                            values: any
                                                        ) => {
                                                            values.id =
                                                                record.id;
                                                            values.game_id =
                                                                selectedGame?.id;
                                                            updateRow(values);
                                                        }}
                                                    >
                                                        <Form.Item
                                                            name="level"
                                                            label="Level"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="coin_reward"
                                                            label="Cash Point"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="starting_xp"
                                                            label="Starting XP"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="xp_reward"
                                                            label="XP"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Button
                                                                type="primary"
                                                                htmlType="submit"
                                                                block
                                                            >
                                                                Update
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </>
                                            }
                                            trigger="click"
                                        >
                                            <Button
                                                size="small"
                                                icon={<EditOutlined />}
                                            >
                                                Edit
                                            </Button>
                                        </Popover>{" "}
                                        <Popconfirm
                                            title="Delete the level row"
                                            description="Are you sure to delete this level row?"
                                            onConfirm={() => {
                                                deleteLevelRow(record);
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                size="small"
                                                icon={<DeleteOutlined />}
                                            >
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                    </>
                                );
                            },
                        },
                    ]}
                    dataSource={list}
                    pagination={false}
                    size="large"
                    summary={() => (
                        <>
                            {addMode && (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>
                                        <Input
                                            placeholder="Level"
                                            onChange={(e) => {
                                                setNewRow({
                                                    ...newRow,
                                                    level: +e.target.value,
                                                });
                                            }}
                                        />
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={1} colSpan={1}>
                                        <Space>
                                            <Input
                                                placeholder="Starting XP"
                                                onChange={(e) => {
                                                    setNewRow({
                                                        ...newRow,
                                                        starting_xp:
                                                            +e.target.value,
                                                    });
                                                }}
                                            />
                                        </Space>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={2} colSpan={1}>
                                        <Space>
                                            <Input
                                                placeholder="Cash Point"
                                                onChange={(e) => {
                                                    setNewRow({
                                                        ...newRow,
                                                        coin_reward:
                                                            +e.target.value,
                                                    });
                                                }}
                                            />
                                            <Input
                                                placeholder="XP"
                                                onChange={(e) => {
                                                    setNewRow({
                                                        ...newRow,
                                                        xp_reward:
                                                            +e.target.value,
                                                    });
                                                }}
                                            />
                                        </Space>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            )}
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4}>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={addNewRow}
                                        disabled={addMode}
                                    >
                                        Add a new Level
                                    </Button>
                                    {addMode && (
                                        <>
                                            {" "}
                                            <Button
                                                type="primary"
                                                icon={<PlusOutlined />}
                                                onClick={saveRow}
                                            >
                                                Save
                                            </Button>
                                        </>
                                    )}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    )}
                ></Table>
            </PageContent>
        </Layout>
    );
}
