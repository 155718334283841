import { createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";

const initialState: AuthReducerInitialState = {
    user: null,
    tokens: null,
};

const authReducer = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setTokens: (state, action) => {
            state.tokens = action.payload;
        },
        logOut: (state, action) => {
            state.user = null;
            state.tokens = null;
        },
    },
    extraReducers: (builder) => {},
});

export const getAuthInfo = async () => {
    const {
        settings,
        user,
        tokens,
        success,
    }: { settings: object; user: object; tokens: object; success: boolean } =
        await api.get("/authenticate/me");

    return {
        settings,
        user,
        tokens,
        success,
    };
};

export const updateUserInfo = async (payload: any) => {
    const { success }: { success: boolean } = await api.post(
        "/authenticate/update_account",
        payload
    );
    return { success };
};

export const resetUserPassword = async (payload: {
    password: string;
    new_password: string;
}) => {
    const { success }: { success: boolean } = await api.put(
        "/authenticate/reset_password",
        payload
    );
    return { success };
};

export const { setUser, setTokens, logOut } = authReducer.actions;
export default authReducer.reducer;
