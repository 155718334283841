import {
    Breadcrumb,
    Button,
    Col,
    Row,
    Upload, Modal, Popover, message as messagePopup
} from "antd";
import Layout from "../../../../components/Layout";
import PageContent from "../../../../components/PageContent";
import PageHeader from "../../../../components/PageHeader";
import { Link, useNavigate } from "react-router-dom";
import './style.css'
import {
    InfoCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { ItemRender, UploadFile } from 'antd/es/upload/interface';
import { useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Content } from "antd/es/layout/layout";
import { getProfilePictures, updateProfilePictures } from "../../../../store/slices/games";


const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });




export default function Customization() {
    const { ui } = useSelector((state: GlobalState) => state);
    const { selectedGame } = ui;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [profilePictures, setProfilePictures] = useState<UploadFile[]>([]);
    const [deletedElements, setDeletedElements] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const uploadRef = useRef();
    const firstList = useRef<any[]>([]);


    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }


        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };


    const handleChange: UploadProps['onChange'] = async({ fileList: newFileList }) => {
        if (!loading) {
            setLoading(true);
            const list: any[] = [...newFileList];
            const newFileLength = newFileList.length;
            const prevStateLength = profilePictures.length;

            if (newFileList.length <= 9) {
                if (newFileLength > prevStateLength) {
                    const newElements = list.filter(item => !profilePictures.includes(item))

                    for (let index = 0; index < newElements.length; index++) {
                        const deletedIndexes = await firstList.current.filter((i: any) => i.active === false)
                        const firstDeletedIndex =  await deletedIndexes && deletedIndexes.length>0 ?  deletedIndexes[0].index : firstList.current.length;
                        await getBase64(newElements[index].originFileObj).then(resp => {
                            firstList.current[firstDeletedIndex] = { image: resp, active: true, index: firstDeletedIndex};
                        }
                        )
                    }
               

                } 

                const fixedImagesArr = await firstList.current.map((item: any) => ({ image: item.url? item.url:item.image, active: item.active }))
                uploadProfilePictures(fixedImagesArr);
                // await Promise.all(fixedImagesArr).then(resp => {
                //     console.log(resp, "3");
                //     const payload = resp.map((i: any) => typeof i === 'string' ? ({ image: i, active: true }) : i);
             
                // });
                
                setProfilePictures(newFileList);
                
                
                
            } else {
                messagePopup.warning('You can not upload more than 9 images. Please delete an image before add.')
                return;
            }
            setLoading(false);
        }
    }





    useEffect(() => {
        getAvatars().then(resp => {
            firstList.current = resp;
        } )

    }, []);



    const getAvatars = async () => {
        try {
            const { images } = await getProfilePictures({ game_id: Number(selectedGame?.id) || 0 });

            const time = Date.now();

            const avatars = images.slice(0,9).filter((i:any)=>i!==null).map((i:string, index:number) => ({ name: i.split("/").pop()?.at(0), url: i+"?"+time , index, active:true }))
            setProfilePictures(avatars);
            return (avatars);
        } catch (error) {
            messagePopup.error("Something went wrong");
            setProfilePictures([]);
        }


    }
    const uploadProfilePictures = async (arr: any) => {
        try {
            const { success, message } = await updateProfilePictures({
                id: Number(selectedGame?.id) || 0,
                images: arr,
            })

            if (success) {
                messagePopup.success('Profile Pictures are uploaded.');

            } else {

                messagePopup.error(message);
                getAvatars();
            }

        } catch (error) {
            messagePopup.error("Something went wrong");
            setProfilePictures([]);
        }

    }

    const deleteAvatar = (a: any) => {
    
        const deletedElementIndex = firstList.current.findIndex(i => i.index === a.index);
        const deletedElementUUId = profilePictures.findIndex(i => i.uid === a.uid)
        const deletedIndex = deletedElementIndex < 0 ? deletedElementUUId : deletedElementIndex;
        const deletedElement = firstList.current[deletedIndex];
        firstList.current[deletedIndex]={...deletedElement, active:false }
    }

    return (
        <Layout>
            <PageHeader>
                <Breadcrumb
                    items={[
                        { title: <Link to="/">{selectedGame?.name || 'Home'}</Link> },
                        { title: "Customization" },
                    ]}
                />

                <Popover
                    placement="leftTop"
                    content={
                        <p style={{ width: 300 }}>
                            You can customize profile pictures of your game from here, just add them and enjoy!
                        </p>
                    }
                    title=""
                >
                    <Button type="default" icon={<InfoCircleOutlined />}>
                        Learn more
                    </Button>
                </Popover>

                {/* </Link> */}
            </PageHeader>
            <PageContent>
                <Row className="wrapper-layout">

                    <Col span={12}>

                        <Content className="photos">
                            <Upload
                                // action=""
                                listType="picture-card"
                                fileList={profilePictures}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                ref={uploadRef}
                                disabled={profilePictures.length > 9}
                                accept="image/jpg"
                                onRemove={deleteAvatar}
                                beforeUpload={(file) => {
                                    const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
                                    if (!isJPG) {
                                        messagePopup.error('You can only upload JPG/JPEG file!');
                                    } 
                                    return false;
                                }}

                                prefixCls="upload"
                                multiple={true}
                            >
                                {profilePictures.length <= 9 &&
                                    <Button className="upload-button" icon={<PlusOutlined />} type="primary">

                                        AVATAR
                                    </Button>}

                            </Upload>

                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>

                        </Content>
                        <p className="warning-avatar">  <InfoCircleOutlined />  Type:JPG / Size:500x500 / Limit:9 Avatars</p>
                    </Col>
                    {/* <Col span={8} className="rules">
                        <Content>
                         
                           
                        </Content>
                    </Col> */}
                </Row>
            </PageContent>
        </Layout>
    );
}
