import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import { Card, Col, Row, Statistic } from "antd";
import GameCalendar from "./components/GameCalendar";
import GameCalendarDatePicker from "./components/GameCalendarDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { setActivePage, selectGame } from "../../store/slices/ui";
import { ArrowUpOutlined, ArrowDownOutlined, MinusOutlined } from "@ant-design/icons";
import SelectedGame from "./components/SelectedGame";
import { fetchMonthlyActiveUser, fetchTotalDistributedCoin, getTournamentsList } from "../../store/slices/games";
import dayjs from "dayjs";
import { coinDivider } from "../../utils/constants";

const upColor = "#3f8600";
const downColor = "#cf1322";

export default function GameDetails() {
    const { user } = useSelector((state: GlobalState) => state.auth);
    const dispatch = useDispatch();
    const location = useLocation();
    const [monthlyActiveUser, setMounthlyActiveUser] = useState<number>(0);
    const [monthlyUserChangingPercentage, setMonthlyUserChangingPercentage] = useState<number>(0);
    const [monthlyRewards, setMonthlyRewards] = useState<number>(0);
    const [monthlyRewardsChangingPercentage, setMonthlyRewardsChangingPercentage] = useState<number>(0);

    useEffect(() => {
        location.state && dispatch(selectGame(location.state));
        dispatch(setActivePage("dashboard"));
        getMonthlyActiveUser();
        getTotalDistributedCoin();
    }, []);


    const date = new Date();
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const previousMounth = dayjs(start).add(-1, 'month');
    const end = dayjs();

    const getMonthlyActiveUser = async () => {
        const { success, monthly_user_count } = await fetchMonthlyActiveUser({
            start_date: start,
            end_date: end,
            game_id:location.state.id
        })

        if (success) {
            setMounthlyActiveUser(monthly_user_count || 0);
            const resp = await fetchMonthlyActiveUser({
                start_date: previousMounth,
                end_date: start,
                game_id: location.state.id
            })

            if (resp.success) {
                const percentage = monthly_user_count && resp.monthly_user_count ? (monthly_user_count- resp.monthly_user_count) / resp.monthly_user_count : 0;
                setMonthlyUserChangingPercentage(percentage * 100);
            }
        }
    }

    const getTotalDistributedCoin = async () => {
        const { success, distributed_coin } = await fetchTotalDistributedCoin({
            start_date: start,
            end_date: end,
            studio_id: location.state.studio.id,
            game_id:location.state.id
        })
        if (success) {
            setMonthlyRewards(Number(distributed_coin)/coinDivider || 0);
            const resp = await fetchTotalDistributedCoin({
                start_date: previousMounth,
                end_date: start,
                studio_id: location.state.studio.id,
                         game_id: location.state.id
            })

            if (resp.success) {
                const percentage = distributed_coin && Number(resp.distributed_coin) ? (Number(distributed_coin) - Number(resp.distributed_coin)) / Number(resp.distributed_coin) : 0;
                setMonthlyRewardsChangingPercentage(percentage * 100)
            }
        }
    }



    return (
        <Layout>
            <PageHeader>
                <Row justify={"space-between"} style={{ width: "100%" }}>
                    <Col span={13}>
                        <h1
                            style={{
                                fontWeight: 200,
                                color: "black",
                                paddingLeft: 20,
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: "LufgaLight",
                                }}
                            >
                                Welcome,{" "}
                            </span>
                            <span
                                style={{
                                    fontWeight: "bold",
                                    fontFamily: "LufgaRegular",
                                }}
                            >
                                {user?.studio.name}
                            </span>
                        </h1>
                    </Col>
                    <Col span={11}>
                        <Row justify={"end"}>
                            {/* <GameCalendarDatePicker /> */}
                            <SelectedGame />
                        </Row>
                    </Col>
                </Row>
            </PageHeader>
            <PageContent>
                <Row gutter={20}>
                    <Col span={12}>
                        <Card>
                            <Statistic
                                title="MAU"
                                value={monthlyActiveUser}
                                valueStyle={{ color: "black" }}
                                suffix={
                                    <div
                                        style={{
                                            fontSize: 16,
                                            marginLeft: 20,
                                            color: monthlyUserChangingPercentage < 0 ? downColor : monthlyUserChangingPercentage===0 ? 'gray' :upColor ,
                                        }}
                                    >
                                        {monthlyUserChangingPercentage <0
                                            ?
                                            <ArrowDownOutlined style={{ color: 'red' }} />
                                            : monthlyUserChangingPercentage === 0 ? null
                                                :
                                            <ArrowUpOutlined style={{ color: 'green' }} />}
                                        {`${monthlyUserChangingPercentage.toFixed(2)} %`}
                                    </div>
                                }
                            />
                        </Card>
                    </Col>

                    {/* <Col span={6}>
                        <Card>
                            <Statistic
                                title="Total User"
                                value={14256}
                                valueStyle={{ color: "black" }}
                                suffix={
                                    <div
                                        style={{
                                            fontSize: 16,
                                            marginLeft: 20,
                                            color: downColor,
                                        }}
                                    >
                                        <ArrowDownOutlined /> 8.2 %
                                    </div>
                                }
                            />
                        </Card>
                    </Col> */}

                    {/* <Col span={6}>
                        <Card>
                            <Statistic
                                title="Retention"
                                value={12.4}
                                valueStyle={{ color: "black" }}
                                precision={2}
                                prefix={"%"}
                            />
                        </Card>
                    </Col> */}

                    <Col span={12}>
                        <Card>
                            <Statistic
                                title={"Rewards"}
                                value={monthlyRewards.toFixed(2)}
                                valueStyle={{ color: "black" }}
                                prefix={"$"}
                                suffix={
                                    <div
                                        style={{
                                            fontSize: 16,
                                            marginLeft: 20,
                                            color: monthlyRewardsChangingPercentage < 0 ? downColor : monthlyRewardsChangingPercentage === 0 ? 'gray' : upColor,
                                        }}
                                    >
                                        {monthlyRewardsChangingPercentage < 0
                                            ?
                                            <ArrowDownOutlined style={{ color: 'red' }} />
                                            : monthlyRewardsChangingPercentage === 0 ? null
                                                :
                                                <ArrowUpOutlined style={{ color: 'green' }} />}
                                        {`${monthlyRewardsChangingPercentage.toFixed(2)} %`}
                                    </div>
                                }
                            />
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row gutter={20}>
                    <Col span={24}>
                        <Card>
                            <GameCalendar />
                        </Card>
                    </Col>
                </Row>
            </PageContent>
        </Layout>
    );
}
