import React, { useEffect, useState } from "react";
import {
    CodeSandboxOutlined,
    CrownOutlined,
    GiftOutlined,
    LineChartOutlined,
    CommentOutlined,
    FileDoneOutlined,
    ArrowLeftOutlined,
    KeyOutlined,
    FormOutlined
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import "./style.css";
import { logOut } from "../../store/slices/auth";
import { Menu } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Avatar } from "antd";
import { setActivePage } from "../../store/slices/ui";
import { removeEmptyStrings } from "../../utils/helpers";
const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

export default function Sidebar() {
    const { user } = useSelector((state: GlobalState) => state.auth);
    const { ui } = useSelector((state: GlobalState) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [defaultOpens, setDefaultOpens] = useState<string[]>();

    const onClick: MenuProps["onClick"] = (e) => {
        if (e.key === "sign-out") {
            dispatch(logOut(1));
            navigate(`/login`);
            dispatch(setActivePage("dashboard"));
        }
        dispatch(setActivePage(e.key));
    };

    const items: MenuProps["items"] = [
        ui.selectedGame ? { type: "divider" } : null,
        ui.selectedGame
            ? getItem(
                  <Link to="/games/manage-games">All games</Link>,
                  "all-games",
                  <ArrowLeftOutlined />
              )
            : null,

        { type: "divider" },

        ui.selectedGame
            ? getItem(
                  <Link to="/game/dashboard" state={ui.selectedGame}>
                      Dashboard
                  </Link>,
                  "dashboard",
                  <LineChartOutlined />
              )
            : null,

        ui.selectedGame
            ? null
            : getItem("Games", "games", <CodeSandboxOutlined />, [
                  getItem(
                      <Link to="/games/manage-games">Manage Games</Link>,
                      "manage-games",
                      null
                  ),
                  ui.selectedGame ? getItem("Configs", "configs", null) : null,
                  ui.selectedGame
                      ? getItem("In-game shop", "in-game-shop", null)
                      : null,
                  getItem(
                      <Link to="/games/api-keys">SDK keys</Link>,
                      "api-keys",
                      null
                  ),
              ]),

        ui.selectedGame
            ? getItem("Events", "events", <CrownOutlined />, [
                  //   getItem("Calendar", "calendar", null),
                // getItem(
                //     <Link to="/events/cash-tournaments">Cash Tournaments</Link>,
                //     "cash-tournaments",
                //     null
                // ),
                getItem(
                      <Link to="/events/tournaments">Tournaments</Link>,
                      "tournaments",
                      null
                ),
                
               
                  //   getItem("Special offers", "special-offers", null),
              ])
            : null,
    

        ui.selectedGame
            ? getItem("Rewards", "rewards", <GiftOutlined />, [
                  //   getItem("Manage pool", "manage-pool", null),
                  getItem(
                      <Link to="/rewards/level-rewards">Level rewards</Link>,
                      "level-rewards",
                      null
                  ),
                  getItem(
                      <Link to="/rewards/region-factors">Region factors</Link>,
                      "region-factors",
                      null
                  ),
                  // daily limit gecici olarak menuden kaldirildi
                  //   getItem(
                  //       <Link to="/rewards/daily-limit">Daily limit</Link>,
                  //       "daily-limit",
                  //       null
                  //   ),
                  //   getItem("Daily limit", "daily-limit", null),
                  //   getItem("Daily bonuses", "daily-bonuses", null),
                  //   getItem("Daily spin", "daily-spin", null),
              ])
            : null,

        ui.selectedGame
            ? null
            : getItem("Cash Rewards", "cash-rewards", <GiftOutlined />, [
                  getItem(
                      <Link to="/cash-rewards/set-allocations">
                          Set allocations
                      </Link>,
                      "set-allocations",
                      null
                  ),
                  getItem(
                      <Link to="/cash-rewards/top-up-balance">
                          Top up balance
                      </Link>,
                      "top-up-balance",
                      null
                  ),
            ]),
        ui.selectedGame ? getItem(
            <Link to="/game/customization">Customization</Link>,
            "customization", <FormOutlined />
        ) : null,

        // getItem("Analytics", "analytics", <LineChartOutlined />),
    ];

    const bottomMenuItems: MenuProps["items"] = [
        // getItem("Give us feedback", "feedback", <MessageOutlined />),
        getItem(
            <a href="https://docs.ratic.io" target="_blank" rel="noreferrer">
                Documentation
            </a>,
            "documentation",
            <FileDoneOutlined />
        ),
        getItem(
            <a
                href="https://wa.me/13024922530"
                target="_blank"
                rel="noreferrer"
            >
                Support
            </a>,
            "support",
            <CommentOutlined />
        ),

        { type: "divider" },

        getItem(
            user?.full_name,
            "user-account-name",
            <Avatar
                size={"small"}
                style={{
                    backgroundColor: "#f1406f",
                    color: "white",
                }}
            >
                {user?.full_name.slice(0, 1)}
            </Avatar>,
            [
                getItem(
                    <Link to="/user/settings">Settings</Link>,
                    "profile-settings",
                    null
                ),
            ]
        ),

        { type: "divider" },

        getItem(
            user?.studio.name,
            "account-name",
            <Avatar
                size={"small"}
                style={{
                    backgroundColor: "#7580E4",
                    color: "white",
                }}
            >
                {user?.studio.name.slice(0, 1)}
            </Avatar>,
            [
                getItem(
                    <Link to="/studio/settings">Settings</Link>,
                    "studio-settings",
                    null
                ),
                // getItem("Billing", "billing", null),
                getItem(
                    <a
                        href="https://ratic.io/pricing"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Plans
                    </a>,
                    "plans",
                    null
                ),
                // getItem("Members", "members", null),
                getItem("Sign out", "sign-out", null),
            ]
        ),
    ];

    useEffect(() => {
        if (!user) {
            /// user is not logged in {anymore}
            /// redirect him to the login page
            navigate("/login");
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if (!defaultOpens) {
            if (window.location.pathname === "/") {
                setDefaultOpens(["games", "manage-games"]);
            } else {
                setDefaultOpens(
                    removeEmptyStrings(location.pathname.split("/"))
                );
            }
        }
        // eslint-disable-next-line
    }, [defaultOpens]);

    return (
        <Sider
            style={{
                overflow: "auto",
                backgroundColor: "#fff",
                borderRight: "1px solid rgba(0,0,0,0.1)",
            }}
        >
            <div className="menuWrapper">
                <div className="menuRow">
                    <div className="LogoBox">
                        <div>
                            <img
                                src="/assets/images/logo_ratic.png"
                                width={100}
                                height={20}
                                alt="Ratic Logo"
                            />
                        </div>
                    </div>
                    {defaultOpens && (
                        <Menu
                            onClick={onClick}
                            defaultOpenKeys={defaultOpens}
                            selectedKeys={defaultOpens}
                            // selectedKeys={["cash-rewards", "set-allocations"]}
                            mode="inline"
                            items={items}
                            onSelect={({ keyPath }) => {
                                setDefaultOpens(keyPath);
                            }}
                        />
                    )}
                </div>
                <div className="menuRow">
                    <Menu
                        defaultOpenKeys={defaultOpens}
                        selectedKeys={defaultOpens}
                        onClick={onClick}
                        mode="inline"
                        items={bottomMenuItems}
                    />
                </div>
            </div>
        </Sider>
    );
}
