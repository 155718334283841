import { Link } from "react-router-dom";
import GameSearchBox from "../../components/GameSearchBox";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import GameBox from "./components/GameBox";
import "./style.css";
import PageContent from "../../components/PageContent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getStudioGames,
    setLoading,
    updateGames,
} from "../../store/slices/games";
import { AppDispatch } from "../../store";
import { Button, Empty, Skeleton } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { selectGame } from "../../store/slices/ui";

export default function Games() {
    const { games } = useSelector((state: GlobalState) => state);
    const [gameList, setGameList] = useState<Game[]>([]);
    const dispatch: AppDispatch = useDispatch();
    const { user } = useSelector((state: GlobalState) => state.auth);

    async function getGames() {
        // rebuild test
        dispatch(setLoading(true));
        const { success, result } = await getStudioGames({
            filter: { studio_id: user?.studio?.id, active: true },
        });

        if (success) {
            dispatch(setLoading(false));
            dispatch(updateGames(result));
        }
    }

    useEffect(() => {
        dispatch(selectGame(null));
        if (user?.id) {
            getGames();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        games.filtered && games.filtered.length && setGameList(games.filtered);
    }, [games]);

    if (!gameList) return null;

    return (
        <Layout>
            <PageHeader>
                <GameSearchBox />
                <Link to="/games/add">
                    <Button type="primary" icon={<PlusOutlined />} size="large">
                        Add new game
                    </Button>
                </Link>
            </PageHeader>
            <PageContent>
                {games.loading ? (
                    <Skeleton active />
                ) : gameList.length === 0 ? (
                    <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{ height: 200 }}
                        description={
                            <span>
                                <h2>There are no games added</h2>
                                <p>Please add a new game</p>
                            </span>
                        }
                    >
                        <Link to={"/games/add"}>
                            <Button type="primary">Add new game</Button>
                        </Link>
                    </Empty>
                ) : (
                    <div className="gamesWrapper">
                        {gameList.map((game: Game, index: number) => (
                            <GameBox key={index} gameInfo={game} />
                        ))}
                    </div>
                )}
            </PageContent>
        </Layout>
    );
}
