import { Breadcrumb, Card } from "antd";
import Layout from "../../../components/Layout";
import PageContent from "../../../components/PageContent";
import { useState } from "react";
import StudioSettings from "./components/StudioSettings";
import ResetPassword from "./components/ResetPassword";
import PageHeader from "../../../components/PageHeader";
import { Link } from "react-router-dom";

export default function Settings() {
    const [state, setState] = useState("studioSettings");

    return (
        <Layout>
            <PageHeader>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to="/games/manage-games">Home</Link>,
                        },
                        { title: "Studio settings" },
                    ]}
                />
            </PageHeader>
            <PageContent>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 100,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Card style={{ width: "400px" }}>
                            {state === "studioSettings" ? (
                                <StudioSettings
                                    state={state}
                                    setState={setState}
                                />
                            ) : (
                                <ResetPassword
                                    state={state}
                                    setState={setState}
                                />
                            )}
                        </Card>
                    </div>
                </div>
            </PageContent>
        </Layout>
    );
}
